import React from 'react'

import 'normalize.css'
import './src/styles/index.scss'

import { ContextProvider } from '@context'

export const wrapRootElement = ({ element }) => (
    <ContextProvider>{element}</ContextProvider>
)
